// FROM: https://github.com/logto-io/js/blob/master/packages/client/src/adapter/defaults.ts

import { JwtVerifier, StandardLogtoClient } from '@logto/client';
import type { JWTVerifyGetKey } from 'jose';
import { jwtVerify } from 'jose';

export const defaultClockTolerance = 300; // 5 minutes

export const verifyIdToken = async (
  idToken: string,
  clientId: string,
  issuer: string,
  jwks: JWTVerifyGetKey,
  clockTolerance = defaultClockTolerance,
) => {
  await jwtVerify(idToken, jwks, { audience: clientId, issuer, clockTolerance });

  // if (Math.abs((result.payload.iat ?? 0) - Date.now() / 1000) > clockTolerance) {
  //   throw new LogtoError('id_token.invalid_iat');
  // }
};

export class DefaultJwtVerifier implements JwtVerifier {
  protected getJwtVerifyGetKey?: JWTVerifyGetKey;

  constructor(
    protected client: StandardLogtoClient,
    public readonly clockTolerance = defaultClockTolerance,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async verifyIdToken(idToken: string): Promise<void> {
    // const { appId } = this.client.logtoConfig;
    // const { issuer, jwksUri } = await this.client.getOidcConfig();
    // this.getJwtVerifyGetKey ||= createRemoteJWKSet(new URL(jwksUri));
    // await verifyIdToken(idToken, appId, issuer, this.getJwtVerifyGetKey, this.clockTolerance);
  }
}
